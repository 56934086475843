var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-job-list" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _vm.params.type != 3 && _vm.jobList.length > 0
            ? _vm._l(_vm.jobList, function (job, index) {
                return _c("market-job-item", {
                  key: index,
                  attrs: { job: job, currentJobType: _vm.params.type },
                  on: {
                    recommend: _vm.recommend,
                    "grab-success-callback": _vm.handleGrabCallback,
                  },
                })
              })
            : _vm.params.type == 3 && _vm.hrJobList.length > 0
            ? _vm._l(_vm.hrJobList, function (job, index) {
                return _c("market-job-item", {
                  key: index,
                  attrs: { job: job, currentJobType: _vm.params.type },
                  on: {
                    recommend: _vm.recommend,
                    "grab-success-callback": _vm.handleGrabCallback,
                  },
                })
              })
            : _c("div", { staticClass: "empty" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ]),
        ],
        2
      ),
      _vm.jobList.length > 0 || _vm.hrJobList.length > 0
        ? _c(
            "el-pagination",
            {
              staticClass: "job-list-pagination",
              attrs: {
                "current-page": _vm.pager.current,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, slot, total, sizes",
                total: _vm.pager.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n                前往\n                "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n                页\n            "),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handlePagerJump(_vm.pagerJump)
                      },
                    },
                  },
                  [_vm._v("\n                跳转\n            ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("grab-result-dialog", {
        ref: "grabResultDialog",
        on: {
          "recommend-btn-callback": _vm.handleRecommendBtnCallback,
          "grab-dialog-close": _vm.grabDialogClose,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }