<template>
    <div class="cloud-candidates-recommend" v-if="isShow">
        <div class="cloud-candidates-container">
            <div class="candidates-recommend-title">
                <font-icon href="#icon-user-two"></font-icon>
                <span class="title">人才推荐</span>
            </div>
            <div
                class="list-wrapper"
                @mousemove="onWrapperMousemove"
                @mouseout="onWrapperMouseout"
            >
                <div class="recommend-list">
                    <a
                        :href="recommend.cloudCandidateUrl"
                        :title="recommend.jobName"
                        v-show="recommend.cloudCandidateCount > 0"
                        target="_blank"
                        v-for="(recommend, index) in recommendList"
                        :key="index"
                    >
                        <span class="job-name">{{recommend.jobName}}</span>（{{recommend.cloudCandidateCount | currency('', 0)}}人）
                    </a>
                </div>
            </div>
            <div class="candidates-recommend-close">
                <i class="el-icon-close" @click="close"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        recommendList: Array
    },
    data() {
        return {
            isShow: true,
            listWrapper: null
        }
    },
    mounted() {
        this.listWrapper = document.querySelector('.list-wrapper');
    },
    methods: {
        close() {
            this.isShow = false;
        },
        onWrapperMousemove(event) {
            let wrapper = this.listWrapper;
            let scrollWidth = wrapper.scrollWidth,
                width = wrapper.clientWidth,
                scrollLeft = wrapper.scrollLeft;
            if(scrollWidth > width) {
                var offsetLeft = event.pageX - wrapper.offsetLeft;
                let animateWidth = offsetLeft / width * (scrollWidth - width);
                wrapper.scrollLeft = animateWidth;
            }
        },
        onWrapperMouseout() {
            let wrapper = this.listWrapper;
        },
    }
}
</script>

<style lang="scss" scope>
.cloud-candidates-recommend {
    position: fixed;
    width: 100%;
    height: 55px;
    left: 0;
    bottom: 0;
    background: #f8f9fa;
    border-top: 1px solid #d3d3d3;
    border-bottom: 1px solid #d3d3d3;
    line-height: 53px;
    z-index: 1001;
    .cloud-candidates-container {
        max-width: 1200px;
        margin: 0 auto;
        width: 100%;
        display: flex;
        .candidates-recommend-title {
            min-width: 100px;
            width: 100px;
            height: 53px;
            line-height: 53px;
            display: flex;
            align-items: center;
            .font-icon {
                width: 26px;
                height: 26px;
                margin: 0 12px 0 15px;
            }
            .title {
                width: 32px;
                line-height: 20px;
                font-size: 16px;
                color: #333;
            }
        }
        .list-wrapper {
            flex-grow: 1;
            border: 1px solid #d3d3d3; 
            border-top: none;
            border-bottom: none;
            height: 73px;
            padding: 10px 0;
            line-height: 0;
            overflow-x: auto;
            .recommend-list {
                white-space: nowrap;
                a {
                    display: inline-block;
                    background: #fff;
                    border: 1px solid $primary;
                    line-height: 31px;
                    padding: 0 14px;
                    margin-left: 12px;
                    border-radius: 4px;
                    color: #666;

                    &:hover {
                        background: #E4F2F0;
                    }

                    &:last-child {
                        margin-right: 14px;
                    }

                    .job-name {
                        max-width: 200px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
        .candidates-recommend-close {
            min-width: 50px;
            width: 50px;
            font-size: 20px;
            line-height: 53px;
            color: #b9b9b9;
            text-align: center;
            cursor: pointer;
            &:hover {
                color: $primary;
            }
        }
    }
}
</style>