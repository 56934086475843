<template>
    <div class="market-container">
        <div class="market-top">
            <market-job-tab
                ref="marketJob"
                :jobList="jobList"
                @search="search"
            ></market-job-tab>
            <search-area
                ref="searchArea"
                :hrJudgeParams="params"
                :isTypeChanged="isTypeChanged"
                @search="search"
            ></search-area>
        </div>
        <div class="market-bottom">
            <div class="market-left">
                <market-job-list
                    ref="marketJob"
                    :jobList="jobList"
                    :hrJobList="hrJobList"
                    :params="params"
                    @search="search"
                    @recommend="showRecommendDialog"
                    @update-list-item="updateListItem"
                ></market-job-list>
            </div>
            <div class="market-right">
                <img src="~@src/assets/images/market/forbid.png" />
            </div>
        </div>

        <recommend-dialog
            ref="recommendDialog"
            :type="1"
        ></recommend-dialog>
        <cloud-candidates-recommend
            :recommendList="recommendList"
            ref="cloudCandidatesRecommend"
            v-if="showCloudCandidatesRecommend"
        ></cloud-candidates-recommend>
    </div>
</template>

<script>
import moment from 'moment';
// import BulletinBoard from './component/bulletin-board.vue';
import SearchArea from './layout/search-area.vue';
import MarketJob from './layout/market-job.vue';
import MarketJobTab from './layout/market-job-tab.vue';
import MarketJobList from './layout/market-job-list.vue';
import RecommendDialog from '#/component/common/dialog/recommendDialog.vue';
import CloudCandidatesRecommend from './component/cloud-candidates-recommend.vue';
export default {
    components: {
        // BulletinBoard,
        SearchArea,
        MarketJob,
        MarketJobTab,
        MarketJobList,
        RecommendDialog,
        CloudCandidatesRecommend,
        MarketJobList,
    },
    data() {
        return {
            params: {
                keyword: "",
                locationId: "",
                jobCategoryId: "",
                type: "0",
                orderBy: 0,
                start: 0,
                take: 10
            },
            jobList: [],
            hrJobList: [],
            recommendList: [],
            showCloudCandidatesRecommend: false,
            isTypeChanged: false,
        }
    },
    computed:{
        user(){
            return this.$store.state.user.userInfo;
        },
        isMarketWhiteList(){
            return this.user.isMarketWhiteList;
        },
        isAdministrator(){
            return this.user.isAdministrator;
        },
        isMarket() {
            return (this.user.privilegeCodeList || []).includes('Market');
        },
    },
    mounted() {
        if(!this.isAdministrator && !this.isMarket){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000);
        } else {
            this.getRecommendList();
        }

        // _request({
        //     method: 'POST',
        //     url: '/User/userinfo',
        //     throwBusinessError: true
        // }).then(res => {
        // _request({
        //     method: 'GET',
        //     url: '/user/userInfo',
        //     baseURL: "LbdJavaApi",
        //     javaProject: "performance",
        //     throwBusinessError: true
        // }).then(res => {
        //     if(res && (res.isMarketWhiteList || res.privilegeCodeList.includes('Market'))) {
        //         this.getRecommendList();
        //     }else {
        //         shortTips('没有市场权限！');
        //         setTimeout(() =>{
        //             // location.href = '/Headhunting/MyCompany.html#/personalCenter';
        //             location.href = '/#/personalCenter';
        //         }, 1000)
        //     }
        // }).catch(err => {
        //     if(err && (err.errorCode == 401 || err.ErrorCode == 401)) {
        //         location.href = '/Account/';
        //     }
        // })
    },
    methods: {
        search(params, silent, isTypeChanged) {
            this.jobList = [];
            this.hrJobList = [];
            Object.assign(this.params, params);
            let requestParam;
            let searchJson = Object.assign({}, this.params);
            // console.log(searchJson)
            if(!silent) {
                this.$refs.marketJob.pager.current = 1;
            }
            searchJson.start = (this.$refs.marketJob.pager.current - 1) * searchJson.take;

            this.isTypeChanged = isTypeChanged;
            if(this.isTypeChanged) {
                searchJson.locationId = "";
                searchJson.jobCategoryId = "";
            }

            if(searchJson.type == '3') {
                // searchJson.startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
                // searchJson.endDate = moment().format('YYYY-MM-DD');
                searchJson.JobLocation = searchJson.locationId ? searchJson.locationId : null;
                searchJson.JobCategoryTopId = searchJson.jobCategoryId ? searchJson.jobCategoryId : null;
                searchJson.JobType = 3;
                delete searchJson.locationId;
                delete searchJson.jobCategoryId;

                requestParam = {
                    url: '/openapi/Job/HRJobList',
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: searchJson
                }
            } else {
                searchJson._ = new Date().getTime();
                requestParam = {
                    url: '/Headhunting/Job/List',
                    method: "GET",
                    data: searchJson
                }
            }
            this.$refs.marketJob.loading = true;

            _request(
                requestParam
            ).then(res => {
                this.$refs.marketJob.loading = false;
                this.$refs.searchArea.isSearch = false;
                // this.jobList = res.list;
                this.$refs.marketJob.pager.total = res.total;

                if(searchJson.type == '3') {
                    // this.jobList = this.jobList.map(el => {
                    //     return {
                    //         ...el,
                    //         jobItem: el
                    //     }
                    // })
                    
                    this.hrJobList = res.list.map(el => {
                        return {
                            ...el,
                            jobItem: el
                        }
                    })
                } else {
                    this.jobList = res.list;
                }

                if(silent) {
                    document.documentElement.scrollTop = document.querySelector(".market-job-list").offsetTop;
                }
                // console.log(this.jobList)
            }).catch(err => {
                this.$refs.marketJob.loading = false;
                this.$refs.searchArea.isSearch = false;
            });
        },
        showRecommendDialog(job, curRecommendJobType) {
            this.$refs.recommendDialog.show(job.id, job.name, curRecommendJobType, job.jobType);
        },
        getRecommendList() {
            _request({
                url: "/Headhunting/Order/GetMyOrderInfoes",
                method: "GET",
                data: {
                    start: 0,
                    take: 20
                }
            }).then(res => {
                let orderJobs = res.list,
                    jobIds = [];
                orderJobs.map((item) => {
                    jobIds.push(item.id);
                });

                if(jobIds.length > 0) {
                    _request({
                        url: "/Headhunting/Job/SuitedCloudCandidateStatistic",
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: {
                            jobIds: jobIds
                        }
                    }).then(res => {
                        let candidatesList = [],
                            total = 0;
                        candidatesList = res.map(item => {
                            for(let j = 0; j < orderJobs.length; j++) {
                                if(item.objectId === orderJobs[j].id) {
                                    total += item.cloudCandidateCount;
                                    return {
                                        jobName: orderJobs[j].name,
                                        jobId: item.objectId,
                                        cloudCandidateUrl: item.cloudCandidateUrl,
                                        cloudCandidateCount: item.cloudCandidateCount
                                    };
                                }
                            }
                        });
                        this.recommendList = candidatesList;
                        if(total > 0) {
                            this.showCloudCandidatesRecommend = true;
                        }
                    }).catch(err => {
                        this.recommendList = [];
                    })
                }
            }).catch(err => {
                this.recommendList = [];
            });
        },
        updateListItem(job) {
            if (this.params.type == 3 && this.hrJobList.length > 0) {
                let index = this.hrJobList.findIndex(item => job.id === item.jobItem.id);
                if (index >= 0) {
                    this.hrJobList[index].jobItem.isMyOrder = true;
                }
            }
            if (this.params.type != 3 && this.jobList.length > 0) {
                let index = this.jobList.findIndex(item => job.id === item.jobItem.id);
                if (index >= 0) {
                    this.jobList[index].jobItem.isMyOrder = true;
                }
            }
        },
    }
}
</script>

<style lang="scss" scope>
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .market-bottom {
        .market-left {
            width: calc(100% - 430px) !important;
        }
        .market-right {
            width: 410px !important;
        }
    }
}
@media screen and (max-width: 1400px) {
    .market-bottom {
        .market-left {
            width: calc(100% - 330px) !important;
        }
        .market-right {
            width: 310px !important;
        }
    }
}

.market-container {
    padding: 20px;
    height: 100%;
    overflow-y: auto;
    // width: 1200px;
    // margin: 0 auto;
    // margin-top: 30px;
    // display: flex;
    // justify-content: space-between;
    .market-top {
        background-color: #fff;
        border-radius: 8px;
    }
    .market-bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .market-left {
            // width: 840px;
            width: calc(100% - 490px);
            height: 100%;
            margin: 0 5px;
            background-color: #fff;
            border-radius: 8px;
        }
        .market-right {
            // width: 300px;
            margin-left: 20px;
            width: 470px;
            img {
                width: 100%;
            }
        }
    }
}
</style>