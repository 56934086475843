var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "market-job-item" }, [
    _c("div", { staticClass: "job-header" }, [
      _c("div", { staticClass: "job-title" }, [
        _c(
          "a",
          {
            staticClass: "job-name ellipsis-overflow",
            class: _vm.job.jobItem.isSleeping ? "is-sleeping" : "",
            attrs: {
              href: "/#/Job/" + _vm.job.jobItem.id,
              title: _vm.job.jobItem.name,
              target: "_blank",
            },
          },
          [
            _vm._v(
              "\n                  " +
                _vm._s(_vm.job.jobItem.name || "") +
                "\n              "
            ),
          ]
        ),
        _vm.currentJobType != 3
          ? _c("span", { staticClass: "job-city ellipsis-overflow" }, [
              _vm._v("[" + _vm._s(_vm.job.jobItem.location || "") + "]"),
            ])
          : _vm._e(),
        _vm.currentJobType == 3
          ? _c("span", { staticClass: "job-city ellipsis-overflow" }, [
              _vm._v("[" + _vm._s(_vm.job.jobItem.locationText) + "]"),
            ])
          : _vm._e(),
        _c(
          "div",
          { staticClass: "job-tags" },
          [
            _vm._l(_vm.job.jobItem.activityTags, function (item) {
              return _c(
                "el-tooltip",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        item.id !== "StandstillJob" &&
                        item.id != "FirstOrderActivityJob",
                      expression:
                        "item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'",
                    },
                  ],
                  key: item.id,
                  attrs: {
                    effect: "light",
                    placement: "right",
                    disabled: !item.description,
                    "popper-class": "job-tag-description",
                  },
                },
                [
                  _c("div", {
                    attrs: { slot: "content" },
                    domProps: { innerHTML: _vm._s(item.description) },
                    slot: "content",
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "job-tag",
                      class: `job-tag-${_vm.jobActivityTag[item.id].theme}`,
                    },
                    [
                      _vm._v(
                        "\n                          " +
                          _vm._s(item.name) +
                          "\n                      "
                      ),
                    ]
                  ),
                ]
              )
            }),
            _vm.isNew(_vm.job.created) && _vm.currentJobType == 3
              ? _c("span", { staticClass: "job-tag job-tag-warning" }, [
                  _vm._v("New"),
                ])
              : _vm._e(),
            _vm.job.jobType == 5 && _vm.currentJobType == 3
              ? _c("span", { staticClass: "job-tag job-tag-info" }, [
                  _vm._v("代运营"),
                ])
              : _vm._e(),
            _vm.job.jobType == 3 && _vm.currentJobType == 3
              ? _c("span", { staticClass: "job-tag job-tag-info" }, [
                  _vm._v("HR"),
                ])
              : _vm._e(),
            _vm.job.jobItem.status !== 1
              ? _c(
                  "span",
                  {
                    staticClass: "job-tag",
                    class: `job-tag-${
                      _vm.jobStatus[_vm.job.jobItem.status].theme
                    }`,
                  },
                  [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.jobStatus[_vm.job.jobItem.status].text) +
                        "\n                  "
                    ),
                  ]
                )
              : _vm._e(),
            _vm.currentJobType == 3 && _vm.job.emergencyDegree == 1
              ? _c("span", { staticClass: "job-tag job-tag-danger" }, [
                  _vm._v("紧急"),
                ])
              : _vm._e(),
            _vm.currentJobType == 3 && _vm.job.difficultDegree == 1
              ? _c("span", { staticClass: "job-tag job-tag-danger" }, [
                  _vm._v("较难"),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
      !_vm.job.jobItem.enableIntelligentRecommend && _vm.job.jobItem.isSticky
        ? _c(
            "span",
            { staticClass: "job-sticky", attrs: { title: "已申领预付款" } },
            [_vm._v("\n              置顶\n          ")]
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "job-bottom" }, [
      _c("div", { staticClass: "job-intro" }, [
        _c("div", { staticClass: "job-info" }, [
          _vm.currentJobType != 3
            ? _c("span", { staticClass: "job-split" }, [
                _vm._v(
                  _vm._s(
                    _vm.job.jobItem.salary == "0K-0K"
                      ? "面议"
                      : _vm.job.jobItem.salary
                  )
                ),
              ])
            : _vm._e(),
          _vm.currentJobType == 3
            ? _c("span", { staticClass: "job-split" }, [
                _vm._v(
                  _vm._s(
                    _vm.job.jobItem.minSalary == 0 &&
                      _vm.job.jobItem.maxSalary == 0
                      ? "面议"
                      : _vm.job.jobItem.minSalary +
                          "K-" +
                          _vm.job.jobItem.maxSalary +
                          "K"
                  )
                ),
              ])
            : _vm._e(),
          _vm.currentJobType != 3
            ? _c("span", { staticClass: "job-split" }, [
                _vm._v(_vm._s(_vm.job.jobItem.degree)),
              ])
            : _vm._e(),
          _vm.currentJobType == 3
            ? _c("span", { staticClass: "job-split" }, [
                _vm.job.jobItem.minYearOfExperience == 0
                  ? _c("span", [_vm._v("经验不限")])
                  : _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("filterYearOfExperience")(
                            _vm.job.jobItem.minYearOfExperience
                          )
                        ) + "经验"
                      ),
                    ]),
              ])
            : _c("span", { staticClass: "job-split" }, [
                _vm._v(
                  "\n                      " +
                    _vm._s(_vm.job.jobItem.yearOfExperience) +
                    "\n                  "
                ),
              ]),
          _vm.currentJobType == 3
            ? _c("span", { staticClass: "job-split" }, [
                _vm._v(
                  _vm._s(_vm._f("degreeFilter")(_vm.job.jobItem.minDegree))
                ),
              ])
            : _vm._e(),
          _c("span", [
            _vm._v("需 " + _vm._s(_vm.job.jobItem.recruitingCount) + " 人"),
          ]),
        ]),
        _c("div", { staticClass: "job-promise" }, [
          _vm._v("\n                  佣金\n                  "),
          _vm.currentJobType != 3
            ? _c("span", { staticClass: "text-warning job-promise-detail" }, [
                _vm._v(
                  _vm._s(
                    _vm.job.jobItem.commissionValue.indexOf("0K-0K") > -1
                      ? "按比例"
                      : _vm.job.jobItem.commissionValue
                  )
                ),
              ])
            : _vm._e(),
          _vm.currentJobType == 3 && _vm.job.commissionType == "0"
            ? _c("span", { staticClass: "text-warning job-promise-detail" }, [
                _vm._v(
                  "\n                      " +
                    _vm._s(
                      _vm.job.jobItem.minSalary == 0 &&
                        _vm.job.jobItem.maxSalary == 0
                        ? `按比例(${_vm.job.jobItem.commissionValue}%)`
                        : (
                            (_vm.job.jobItem.minSalary *
                              12 *
                              _vm.job.jobItem.commissionValue) /
                            100
                          ).toFixed(2) +
                            "K - " +
                            (
                              (_vm.job.jobItem.maxSalary *
                                12 *
                                _vm.job.jobItem.commissionValue) /
                              100
                            ).toFixed(2) +
                            "K(" +
                            _vm.job.jobItem.commissionValue +
                            "%)"
                    ) +
                    "\n                  "
                ),
              ])
            : _vm._e(),
          _vm.currentJobType == 3 && _vm.job.commissionType == "1"
            ? _c("span", { staticClass: "text-warning job-promise-detail" }, [
                _vm._v(
                  "\n                      " +
                    _vm._s(_vm.job.jobItem.commissionValue + "K") +
                    "\n                  "
                ),
              ])
            : _vm._e(),
          _vm._v("\n                  保证期 "),
          _c("span", { staticClass: "text-warning job-promise-detail" }, [
            _vm._v(_vm._s(_vm.job.jobItem.guaranteePeriod)),
          ]),
          _vm._v(" 个月\n              "),
        ]),
        _vm.currentJobType != 3 &&
        _vm.user.headhuntingFirmType > 0 &&
        ((_vm.user.headhuntingFirmType > 2 &&
          _vm.job.jobItem.headhuntingFirmCommissionValue > 0) ||
          (_vm.user.headhuntingFirmType < 3 &&
            _vm.job.jobItem.otherFirmCommissionValue > 0))
          ? _c(
              "div",
              { staticClass: "job-firm-commission" },
              [
                _vm._v("\n                  分佣比例\n                  "),
                _c(
                  "span",
                  { staticClass: "text-grey job-firm-commission-detail" },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.user.headhuntingFirmType > 2
                          ? _vm.job.jobItem.headhuntingFirmCommissionValue + "%"
                          : _vm.job.jobItem.otherFirmCommissionValue + "%"
                      )
                    ),
                  ]
                ),
                _vm.user.headhuntingFirmType < 3
                  ? _c(
                      "el-tooltip",
                      {
                        attrs: {
                          "popper-class": "commission-rate-text",
                          effect: "light",
                          content: "简历提供者，分成比例需要和平台运营确认。",
                          placement: "right",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "tip-icon" },
                          [
                            _c("font-icon", {
                              staticClass: "default",
                              attrs: { href: "#icon-talent_qd" },
                            }),
                            _c("font-icon", {
                              staticClass: "hover",
                              attrs: { href: "#icon-talent_ql" },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.currentJobType == "0"
        ? _c("div", { staticClass: "job-status" }, [
            _c(
              "a",
              {
                staticClass: "job-firmname",
                class: {
                  "firmname-no-view": !_vm.job.jobItem.canViewFirmDetail,
                },
                attrs: {
                  title:
                    _vm.job.jobItem.firmShortName || _vm.job.jobItem.firmName,
                  href: _vm.job.jobItem.canViewFirmDetail
                    ? `/#/Customer/${_vm.job.jobItem.customerId}`
                    : "javascript:void(0)",
                  target: _vm.job.jobItem.canViewFirmDetail
                    ? "_blank"
                    : "_self",
                },
              },
              [
                _vm._v(
                  "\n                  " +
                    _vm._s(
                      _vm.job.jobItem.firmShortName || _vm.job.jobItem.firmName
                    ) +
                    "\n              "
                ),
              ]
            ),
            _c("div", { staticClass: "job-channel" }, [
              _c("span", {
                staticClass: "job-channel-indicator",
                class: _vm.job.isOnline ? "active" : "",
              }),
              _c("span", { staticClass: "job-channel-user" }, [
                _vm._v(
                  _vm._s(_vm.job.jobItem.realName) +
                    "（" +
                    _vm._s(
                      _vm.job.isOnline
                        ? "在线"
                        : _vm.offlineFilter(_vm.job.offlineTime)
                    ) +
                    "）"
                ),
              ]),
            ]),
            _c("div", { staticClass: "job-entrance" }, [
              _vm.job.onlineCount > 0
                ? _c("span", { staticClass: "job-entrance-count" }, [
                    _vm._v(
                      "\n                      " +
                        _vm._s(_vm.job.onlineCount) +
                        "人正在讨论\n                  "
                    ),
                  ])
                : _vm._e(),
              !_vm.job.jobItem.isVerifying
                ? _c(
                    "span",
                    {
                      staticClass: "text-operate-btn green",
                      on: {
                        click: function ($event) {
                          return _vm.goToChannel(_vm.job.jobItem)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                      立即参与 »\n                  "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
      _vm.currentJobType == 3
        ? _c("div", { staticClass: "job-status-hr" }, [
            _vm.job.recruiter
              ? _c("span", { staticClass: "job-publisher" }, [
                  _vm._v(_vm._s(_vm.job.recruiter.realName)),
                ])
              : _vm._e(),
            _c(
              "a",
              {
                staticClass: "job-firmname",
                attrs: {
                  title: [3, 5].includes(_vm.job.jobType)
                    ? _vm.job.definedCustomerName || _vm.job.firmName
                    : _vm.job.firmName,
                  href:
                    _vm.job.jobType == 3
                      ? `/Headhunting/#/hrFirm?customerId=${_vm.job.jobItem.firmId}`
                      : "javascript:void(0)",
                  target: _vm.job.jobType == 3 ? "_blank" : "_self",
                },
              },
              [
                _vm._v(
                  "\n                  " +
                    _vm._s(
                      [3, 5].includes(_vm.job.jobType)
                        ? _vm.job.definedCustomerName || _vm.job.firmName
                        : _vm.job.firmName
                    ) +
                    "\n              "
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "job-panel" },
        [
          !(_vm.job.jobItem.isMyOrder || _vm.job.jobItem.isMyJob)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.grabLoading },
                  on: {
                    click: function ($event) {
                      return _vm.getOrder(_vm.job.jobItem)
                    },
                  },
                },
                [_vm._v("\n                  抢单\n              ")]
              )
            : _vm._e(),
          _vm.job.jobItem.isMyOrder || _vm.job.jobItem.isMyJob
            ? _c(
                "el-button",
                {
                  staticClass: "recommend-button",
                  on: {
                    click: function ($event) {
                      return _vm.recommend(_vm.job.jobItem, _vm.currentJobType)
                    },
                  },
                },
                [_vm._v("\n                  推荐\n              ")]
              )
            : _vm._e(),
          _vm.job.jobItem.fitCount
            ? _c(
                "a",
                {
                  staticClass: "job-fit",
                  attrs: { href: _vm.job.jobItem.fitUrl, target: "_blank" },
                },
                [
                  _c("i", { staticClass: "el-icon-search" }),
                  _vm._v(
                    "\n                  有 " +
                      _vm._s(_vm.job.jobItem.fitCount || 0) +
                      " 人适合此职位\n              "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }