var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isShow
    ? _c("div", { staticClass: "cloud-candidates-recommend" }, [
        _c("div", { staticClass: "cloud-candidates-container" }, [
          _c(
            "div",
            { staticClass: "candidates-recommend-title" },
            [
              _c("font-icon", { attrs: { href: "#icon-user-two" } }),
              _c("span", { staticClass: "title" }, [_vm._v("人才推荐")]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "list-wrapper",
              on: {
                mousemove: _vm.onWrapperMousemove,
                mouseout: _vm.onWrapperMouseout,
              },
            },
            [
              _c(
                "div",
                { staticClass: "recommend-list" },
                _vm._l(_vm.recommendList, function (recommend, index) {
                  return _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: recommend.cloudCandidateCount > 0,
                          expression: "recommend.cloudCandidateCount > 0",
                        },
                      ],
                      key: index,
                      attrs: {
                        href: recommend.cloudCandidateUrl,
                        title: recommend.jobName,
                        target: "_blank",
                      },
                    },
                    [
                      _c("span", { staticClass: "job-name" }, [
                        _vm._v(_vm._s(recommend.jobName)),
                      ]),
                      _vm._v(
                        "（" +
                          _vm._s(
                            _vm._f("currency")(
                              recommend.cloudCandidateCount,
                              "",
                              0
                            )
                          ) +
                          "人）\n                "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c("div", { staticClass: "candidates-recommend-close" }, [
            _c("i", { staticClass: "el-icon-close", on: { click: _vm.close } }),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }