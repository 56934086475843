var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "market-search" }, [
    _c(
      "div",
      { staticClass: "search-container" },
      [
        _c("el-input", {
          staticClass: "search-input",
          attrs: {
            placeholder: "请输入职位/公司关键词",
            maxlength: 128,
            disabled: _vm.isSearch,
          },
          on: { change: _vm.handleKeyword, blur: _vm.handleKeywordBlur },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.handleKeyword.apply(null, arguments)
            },
          },
          model: {
            value: _vm.keyword,
            callback: function ($$v) {
              _vm.keyword = $$v
            },
            expression: "keyword",
          },
        }),
      ],
      1
    ),
    _vm.cityFilters.length > 0 || _vm.jobFilters.length > 0
      ? _c("div", { staticClass: "market-job-filter" }, [
          _vm.cityFilters.length > 0
            ? _c("div", { staticClass: "job-filter filter-city" }, [
                _c("span", { staticClass: "job-filter-title" }, [
                  _vm._v("\n                位置：\n            "),
                ]),
                _c(
                  "ul",
                  { staticClass: "job-filter-list" },
                  _vm._l(_vm.cityFilters, function (city, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "job-filter-item",
                        class:
                          city.cityId == _vm.selectedCityId ? "selected" : "",
                        on: {
                          click: function ($event) {
                            return _vm.filterCity(city.cityId)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(city.name) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.hrJudgeParams.type != 3 && _vm.jobFilters.length > 0
            ? _c("div", { staticClass: "job-filter filter-city" }, [
                _c("span", { staticClass: "job-filter-title" }, [
                  _vm._v("\n                职位：\n            "),
                ]),
                _c(
                  "ul",
                  { staticClass: "job-filter-list" },
                  _vm._l(_vm.jobFilters, function (job, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass: "job-filter-item",
                        class: job.id == _vm.selectedJob.id ? "selected" : "",
                        on: {
                          click: function ($event) {
                            return _vm.filterJob(job)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(job.name) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }