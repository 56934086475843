var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-container" },
    [
      _c(
        "div",
        { staticClass: "market-top" },
        [
          _c("market-job-tab", {
            ref: "marketJob",
            attrs: { jobList: _vm.jobList },
            on: { search: _vm.search },
          }),
          _c("search-area", {
            ref: "searchArea",
            attrs: {
              hrJudgeParams: _vm.params,
              isTypeChanged: _vm.isTypeChanged,
            },
            on: { search: _vm.search },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "market-bottom" }, [
        _c(
          "div",
          { staticClass: "market-left" },
          [
            _c("market-job-list", {
              ref: "marketJob",
              attrs: {
                jobList: _vm.jobList,
                hrJobList: _vm.hrJobList,
                params: _vm.params,
              },
              on: {
                search: _vm.search,
                recommend: _vm.showRecommendDialog,
                "update-list-item": _vm.updateListItem,
              },
            }),
          ],
          1
        ),
        _vm._m(0),
      ]),
      _c("recommend-dialog", { ref: "recommendDialog", attrs: { type: 1 } }),
      _vm.showCloudCandidatesRecommend
        ? _c("cloud-candidates-recommend", {
            ref: "cloudCandidatesRecommend",
            attrs: { recommendList: _vm.recommendList },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "market-right" }, [
      _c("img", {
        attrs: { src: require("@src/assets/images/market/forbid.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }