<template>
    <div class="market-job">
        <div class="market-job-header">
            <el-tabs
                class="market-job-tab"
                v-model="params.type"
                type="card"
                @tab-click="clickType"
            >
                <el-tab-pane
                    label="市场职位"
                    name="0"
                ></el-tab-pane>
                <el-tab-pane
                    label="新职位"
                    name="1"
                ></el-tab-pane>
                <el-tab-pane
                    label="刚需职位"
                    name="2"
                ></el-tab-pane>
            </el-tabs>
            <span :class="isNewJob ? 'new-job-tip' : ''"></span>
            <el-select
                class="orderby-select-input"
                v-model="params.orderBy"
                placeholder="请选择"
                popper-class="orderby-select"
            >
                <el-option
                    label="最近更新"
                    :value="0"
                ></el-option>
                <el-option
                    label="最近发布"
                    :value="1"
                ></el-option>
                <el-option
                    label="高佣金"
                    :value="2"
                ></el-option>
                <el-option
                    label="高百分比"
                    :value="3"
                ></el-option>
            </el-select>
        </div>
        <div class="market-job-list" v-loading="loading">
            <template v-if="jobList.length > 0">
                <market-job-item
                    v-for="(job, index) in jobList"
                    :key="index"
                    :job="job"
                    @recommend="recommend"
                ></market-job-item>
            </template>
            <div class="empty" v-else>
                <span class="empty-img"></span>
                <p class="empty-data">暂无数据</p>
            </div>
        </div>
        <el-pagination
            :current-page="pager.current"
            :page-sizes="[10, 30, 50]"
            :page-size="pager.size"
            class="job-list-pagination"
            layout="prev, pager, next, slot, total, sizes"
            :total="pager.total"
            v-if="jobList.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
            <span class="pagination-text">
                <span>
                    前往
                    <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                    页
                </span>
                <span @click="handlePagerJump(pagerJump)">
                    跳转
                </span>
            </span>
        </el-pagination>
    </div>
</template>

<script>
import MarketJobItem from '../component/market-job-item.vue';
export default {
    props: {
        jobList: Array
    },
    components: {
        MarketJobItem,
    },
    data() {
        return {
            params: {
                type: "0",
                orderBy: 0
            },
            loading: false,
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            pagerJump: 0,
            isNewJob: false
        }
    },
    watch: {
        params: {
            handler: function() {
                this.search(false);
            },
            deep: true
        }
    },
    mounted() {
        _request({
            url: "/Market/HasUnreadNewJobs",
            method: "POST",
            throwBusinessError: true
        }).then(res => {})
        .catch(res => {
            this.isNewJob = res;
        })
    },
    methods: {
        clickType(tab) {
            if(tab.name == '1') {
                this.isNewJob = false;
            }
        },
        search(silent) {
            let searchJson = Object.assign({}, this.params);
            searchJson.take = this.pager.size;
            this.$emit('search', searchJson, silent);
            // console.log(this.params);
        },
        recommend(job) {
            this.$emit('recommend', job)
        },
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.search(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.search(true);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
    }
}
</script>

<style lang="scss" scope>
    .market-job {
        margin-top: 20px;
        .market-job-header {
            width: 100%;
            height: 48px;
            color: #666;
            background-color: #f6f6f6;
            position: relative;
        }
        .market-job-tab {
            display: inline-block;
            .el-tabs__header {
                border-bottom: none;
                margin-bottom: 0;
                .el-tabs__nav {
                    border: none;
                    .el-tabs__item {
                        width: 130px;
                        height: 48px;
                        line-height: 48px;
                        padding-left: 0;
                        padding-right: 0;
                        border-left: none;
                        border-bottom: none;
                        font-weight: bold;
                        font-size: 16px;
                        text-align: center;
                        color: #333;
                        &.is-active {
                            color: #333;
                            border: 1px solid #d3d3d3;
                            border-top: 2px solid #38bc9d;
                            border-bottom: none;
                            background-color: #fff;
                            position: relative;
                            &::after {
                                width: 128px;
                                height: 1px;
                                display: inline-block;
                                background-color: #fff;
                                position: absolute;
                                bottom: -1px;
                                left: 1px;
                            }
                        }
                    }
                }
            }
        }
        .new-job-tip {
            width: 8px;
            height: 8px;
            display: inline-block;
            position: absolute;
            top: 15px;
            left: 220px;
            border-radius: 50%;
            z-index: 2;
            background-color: #ff493c;
        }
        .orderby-select-input {
            position: absolute;
            right: 0;
            top: 0;
            .el-input__inner {
                height: 48px;
                line-height: 48px;
                padding-left: 0;
                width: 82px;
                border: none;
                color: #b9b9b9;
                padding-right: 17px;
                background-color: inherit;
                &:hover, &:focus {
                    border: none;
                }
            }
            .el-input {
                .el-select__caret {
                    color: #b9b9b9;
                    font-size: 12px;
                    width: auto;
                }
            }
        }
        .empty {
            min-height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            .empty-img {
                display: inline-block;
                background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
                background-size: contain;
                width: 170px;
                height: 160px;
            }
            .empty-data {
                font-size: 14px;
                margin-bottom: 0px;
            }
        }
        .el-loading-spinner {
            top: 70px;
        }
        .job-list-pagination.el-pagination {
            margin: 40px 0 20px 0;
            padding: 0;
            font-weight: normal;
            .btn-next, .btn-prev {
                height: 38px;
                border: 1px solid;
            }
            .btn-prev {
                border-right: none;
                border-radius: 4px 0 0 4px;
            }
            .btn-next {
                border-left: none;
                border-radius: 0 4px 4px 0;
            }
            .el-pagination__sizes .el-input{
                width: 87px;
                .el-input__inner {
                    color: #666;
                    .el-select__caret{
                        color: #999;
                    }
                }
            }
            .el-pager {
                border-top: 1px solid;
                border-bottom: 1px solid;
                li {
                    min-width: 36px;
                    height: 36px;
                    line-height: 36px;
                    font-size: 14px;
                }
            }
            .btn-next, .btn-prev, .el-pager {
                border-color: #DDDDDD;
            }
            .el-input__inner, .el-pagination__total, .el-pagination__jump {
                height: 38px;
                line-height: 38px;
                font-size: 14px;
            }
            .pagination-text{
                color: #999;
                span{
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    .el-input{
                        width: 48px;
                        margin: 0 5px;
                        &__inner{
                            height: 38px;
                            line-height: 38px;
                            padding: 0 5px;
                        }
                    }
                    &:nth-of-type(2){
                        margin-left: 5px;
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
            .el-pagination__total,
            .el-pagination__sizes,
            .pagination-text {
                float: right;
            }
        }
    }
</style>

<style lang="scss">
.orderby-select.el-select-dropdown {
    .el-select-dropdown__item {
        padding: 0;
        width: 82px;
        text-align: center;
    }
}
</style>