var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-job-header" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "market-job-tab",
          attrs: { type: "card" },
          on: { "tab-click": _vm.clickType },
          model: {
            value: _vm.params.type,
            callback: function ($$v) {
              _vm.$set(_vm.params, "type", $$v)
            },
            expression: "params.type",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "A2A职位", name: "0" } }),
          _c("el-tab-pane", { attrs: { label: "HR直招", name: "3" } }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }