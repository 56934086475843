<template>
    <div class="market-search">
        <div class="search-container">
            <el-input
                placeholder="请输入职位/公司关键词"
                v-model="keyword"
                class="search-input"
                :maxlength="128"
                :disabled="isSearch"
                @change="handleKeyword"
                @blur="handleKeywordBlur"
                @keyup.enter.native="handleKeyword"
            >
                <!-- <el-button
                    slot="append"
                    class="search-button"
                    type="primary"
                    :disabled="isSearch"
                    @click="handleKeyword"
                >
                    查询
                </el-button> -->
            </el-input>
        </div>
        <div class="market-job-filter" v-if="cityFilters.length > 0 || jobFilters.length > 0">
            <div class="job-filter filter-city" v-if="cityFilters.length > 0">
                <span class="job-filter-title">
                    位置：
                </span>
                <ul class="job-filter-list">
                    <li
                        class="job-filter-item"
                        :class="city.cityId == selectedCityId ? 'selected' : ''"
                        v-for="(city, index) in cityFilters"
                        :key="index"
                        @click="filterCity(city.cityId)"
                    >
                        {{city.name}}
                    </li>
                </ul>
            </div>
            <!-- <hr class="job-filter-hr" v-if="cityFilters.length > 0 && jobFilters.length > 0"> -->
            <div class="job-filter filter-city" v-if="hrJudgeParams.type != 3 && jobFilters.length > 0">
                <span class="job-filter-title">
                    职位：
                </span>
                <ul class="job-filter-list">
                    <li
                        class="job-filter-item"
                        :class="job.id == selectedJob.id ? 'selected' : ''"
                        v-for="(job, index) in jobFilters"
                        :key="index"
                        @click="filterJob(job)">
                        {{job.name}}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import JobCategoryService from '#/js/service/jobCategoryService.js';
import { data as dataUrl } from '#/js/config/api.json';
export default {
    props: {
        hrJudgeParams: Object,
        isTypeChanged: Boolean
    },
    data() {
        return {
            keyword: "",
            isSearch: false,
            cityFilters: [],
            jobFilters: [],
            selectedCityId: "",
            selectedJob: {
                id: "",
                name: ""
            },
            params: {
                keyword: "",
                locationId: "",
                jobCategoryId: ""
            },
        }
    },
    mounted() {
        this.getCityFilters();
        this.getJobFilters();
        if(!this.$route.query.JobCategoryId || this.$route.query.JobCategoryId == 0) {
            this.search();
        }
        if(this.isTypeChanged) {
            this.selectedCityId = "";
        }
    },
    watch: {
        isTypeChanged(val) {
            this.isTypeChanged = val;
            if(this.isTypeChanged) {
                this.selectedCityId = "";
            }
        }
    },
    methods: {
        getCityFilters() {
            _request({
                url: "/data/popularCities",
                method: "GET",
                data: {
                    _: new Date().getTime()
                }
            }).then(res => {
                res.unshift({
                    name: "全部地区",
                    shortName: "",
                    provinceId: "",
                    cityId: ""
                })
                this.cityFilters = res;
            }).catch(err => {
                console.log(err);
            });
        },
        getJobFilters() {
            // JobCategoryService.getCategoryList({
            //     firmId: this.$store.state.user.userInfo.firmId,
            //     hasChildrenJobCategory: false,
            //     hasJobCount: false
            // }).
            // const params = {
            //     hasChildrenJobCategory: true,
            //     hasJobCount: true,
            // };
            // JobCategoryService
            // .jobCategoriesTree(params)
            _request({
                url: dataUrl.job_category,
                method: 'GET',
                apiType: 'oldApi',
            }).then(r => {
                const res = r.subJobCategories;
                let list = [];
                if(res && res.length) {
                    // list = res.slice(0, res.length);
                    list = res.slice(1);
                } else {
                    list = [];
                }
                list.unshift({
                    id: "",
                    name: "全部类别"
                })
                this.jobFilters = list;
            }).catch(err => {
                console.log(err)
            })
        },
        search() {
            this.$emit('search', this.params, false);
        },
        handleKeyword() {
            this.params.keyword = this.keyword;
            this.isSearch = true;
            this.search();
        },
        handleKeywordBlur() {
            if(this.params.keyword != this.keyword) {
                this.handleKeyword();
            }
        },
        filterCity(id) {
            if(this.id == id) {
                this.selectedCityId = "";
            } else {
                this.selectedCityId = id;
            }
            this.params.locationId = this.selectedCityId;
            this.search();
        },
        filterJob(job) {
            let selectedJob = this.selectedJob;
            if(selectedJob.id == job.id) {
                selectedJob.id = "";
                selectedJob.name = "";
            } else {
                selectedJob.id = job.id;
                selectedJob.name = job.name;
            }
            this.params.jobCategoryId = selectedJob.id;
            this.search();
        }
    }
}
</script>

<style lang="scss" scope>
.market-search {
    width: 100%;
    display: flex;
    flex-direction: column;
    // background-color: #f6f6f6;
    .search-container {
        // margin-bottom: 10px;
        margin: 24px 12px 5px;
        .search-input {
            width: 100%;
            .el-input__inner {
                height: 46px;
                line-height: 46px;
                // border: 2px solid #38bc9d;
                border: 1px solid #DDDDDD;
                padding-left: 10px;
                color: rgb(102, 102, 102);
            }
            .search-button {
                width: 160px;
                height: 46px;
                line-height: 46px;
                font-size: 16px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                border: none;
                background: $primary;
                color: #fff;
                &:hover, &:focus {
                    background-color: #48CAAC;
                    border-color: #48CAAC;
                }
                &:active {
                    background-color: #36B495;
                    border-color: #36B495;
                }
                &.is-disabled {
                    color: #b9b9b9;
                    border-color: #ececec;
                }
            }
            &.is-disabled {
                .el-input__inner {
                    color: #b9b9b9;
                }
            }
        }
    }
    .market-job-filter {
        // border: 1px solid #e1e1e1;
        // background-color: #f6f6f6;
        // padding: 15px 20px;
        padding: 15px 15px 0;
        .job-filter-hr {
            width: 100%;
            border: none;
            border-bottom: 1px dashed #e1e1e1;
            margin-top: 15px;
            margin-bottom: 15px;
            box-sizing: content-box;
            height: 0;
        }
        .job-filter {
            width: 100%;
            line-height: 25px;
            display: flex;
            margin-bottom: 20px;
            .job-filter-title {
                width: 42px;
                color: #333;
                margin-right: 5px;
                display: inline-block;
                white-space: nowrap;
            }
            .job-filter-list {
                flex-grow: 1;
                white-space: wrap;
                margin-bottom: 0;
                .job-filter-item {
                    display: inline-block;
                    border-radius: 2px;
                    padding: 0 10px;
                    margin-right: 5px;
                    position: relative;
                    &:hover {
                        cursor: pointer;
                        background-color: #e9e9e9;
                    }
                    &.selected {
                        color: #fff;
                        background-color: $primary;
                        &:hover {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
</style>