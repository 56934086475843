var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "market-job" },
    [
      _c(
        "div",
        { staticClass: "market-job-header" },
        [
          _c(
            "el-tabs",
            {
              staticClass: "market-job-tab",
              attrs: { type: "card" },
              on: { "tab-click": _vm.clickType },
              model: {
                value: _vm.params.type,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "type", $$v)
                },
                expression: "params.type",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "市场职位", name: "0" } }),
              _c("el-tab-pane", { attrs: { label: "新职位", name: "1" } }),
              _c("el-tab-pane", { attrs: { label: "刚需职位", name: "2" } }),
            ],
            1
          ),
          _c("span", { class: _vm.isNewJob ? "new-job-tip" : "" }),
          _c(
            "el-select",
            {
              staticClass: "orderby-select-input",
              attrs: {
                placeholder: "请选择",
                "popper-class": "orderby-select",
              },
              model: {
                value: _vm.params.orderBy,
                callback: function ($$v) {
                  _vm.$set(_vm.params, "orderBy", $$v)
                },
                expression: "params.orderBy",
              },
            },
            [
              _c("el-option", { attrs: { label: "最近更新", value: 0 } }),
              _c("el-option", { attrs: { label: "最近发布", value: 1 } }),
              _c("el-option", { attrs: { label: "高佣金", value: 2 } }),
              _c("el-option", { attrs: { label: "高百分比", value: 3 } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "market-job-list",
        },
        [
          _vm.jobList.length > 0
            ? _vm._l(_vm.jobList, function (job, index) {
                return _c("market-job-item", {
                  key: index,
                  attrs: { job: job },
                  on: { recommend: _vm.recommend },
                })
              })
            : _c("div", { staticClass: "empty" }, [
                _c("span", { staticClass: "empty-img" }),
                _c("p", { staticClass: "empty-data" }, [_vm._v("暂无数据")]),
              ]),
        ],
        2
      ),
      _vm.jobList.length > 0
        ? _c(
            "el-pagination",
            {
              staticClass: "job-list-pagination",
              attrs: {
                "current-page": _vm.pager.current,
                "page-sizes": [10, 30, 50],
                "page-size": _vm.pager.size,
                layout: "prev, pager, next, slot, total, sizes",
                total: _vm.pager.total,
              },
              on: {
                "size-change": _vm.handleSizeChange,
                "current-change": _vm.handleCurrentChange,
              },
            },
            [
              _c("span", { staticClass: "pagination-text" }, [
                _c(
                  "span",
                  [
                    _vm._v("\n                前往\n                "),
                    _c("el-input", {
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handlePagerJump(_vm.pagerJump)
                        },
                      },
                      model: {
                        value: _vm.pagerJump,
                        callback: function ($$v) {
                          _vm.pagerJump = $$v
                        },
                        expression: "pagerJump",
                      },
                    }),
                    _vm._v("\n                页\n            "),
                  ],
                  1
                ),
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.handlePagerJump(_vm.pagerJump)
                      },
                    },
                  },
                  [_vm._v("\n                跳转\n            ")]
                ),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }