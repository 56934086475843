<template>
    <div class="market-job-list">
        <div v-loading="loading">
            <template v-if="params.type!=3 && jobList.length > 0">
                <market-job-item
                    v-for="(job, index) in jobList"
                    :key="index"
                    :job="job"
                    :currentJobType="params.type"
                    @recommend="recommend"
                    @grab-success-callback="handleGrabCallback"
                ></market-job-item>
            </template>
            <template v-else-if="params.type == 3 && hrJobList.length > 0">
                <market-job-item
                    v-for="(job, index) in hrJobList"
                    :key="index"
                    :job="job"
                    :currentJobType="params.type"
                    @recommend="recommend"
                    @grab-success-callback="handleGrabCallback"
                ></market-job-item>
            </template>
            <div class="empty" v-else>
                <span class="empty-img"></span>
                <p class="empty-data">暂无数据</p>
            </div>
        </div>
        <el-pagination
            :current-page="pager.current"
            :page-sizes="[10, 30, 50]"
            :page-size="pager.size"
            class="job-list-pagination"
            layout="prev, pager, next, slot, total, sizes"
            :total="pager.total"
            v-if="jobList.length > 0 || hrJobList.length > 0"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
            <span class="pagination-text">
                <span>
                    前往
                    <el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump(pagerJump)"></el-input>
                    页
                </span>
                <span @click="handlePagerJump(pagerJump)">
                    跳转
                </span>
            </span>
        </el-pagination>

        <!-- 抢单成功后的弹出窗口 -->
        <grab-result-dialog
            ref="grabResultDialog"
            @recommend-btn-callback="handleRecommendBtnCallback"
            @grab-dialog-close="grabDialogClose"
            >
        </grab-result-dialog>
    </div>
</template>

<script>
import MarketJobItem from '../component/market-job-item.vue';
import GrabResultDialog from '#/views/job-list/component/grab-result-dialog.vue';
export default {
    props: {
        jobList: Array,
        hrJobList: Array,
        params: Object
    },
    components: {
        MarketJobItem,
        GrabResultDialog,
    },
    data() {
        return {
            loading: false,
            pager: {
                current: 1,
                size: 10,
                total: 0
            },
            pagerJump: 0,
        }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        search(silent) {
            // console.log(2222)
            // console.log(this.params);
            let searchJson = Object.assign({}, this.params);
            searchJson.take = this.pager.size;
            this.$emit('search', searchJson, silent);
        },
        recommend(job,type) {
            this.$emit('recommend', job,type)
        },
        handleGrabCallback(job) {
            this.$refs.grabResultDialog.show(job);
        },
        handleRecommendBtnCallback(job) {
            this.recommend(job, this.params.type);
        },
        grabDialogClose(job) {
            this.$emit('update-list-item', job);
        },
        // 分页
        handleSizeChange(val){
            this.pager.size = val;
            this.pager.current = 1;
            this.search(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.search(true);
        },
        handlePagerJump(pagerJump) {
            // if(pagerJump > Math.ceil(this.pager.total/this.pager.size)) {
            //     return;
            // } else {
            //     this.handleCurrentChange(pagerJump);
            // }
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
    }
}
</script>

<style lang="scss" scope>
.market-job-list {
    // margin-top: 10px;
    padding: 20px;
    .empty {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .empty-img {
            display: inline-block;
            background: url('~@src/assets/images/home/icon_no_data.svg') no-repeat;
            background-size: contain;
            width: 170px;
            height: 160px;
        }
        .empty-data {
            font-size: 14px;
            margin-bottom: 0px;
        }
    }
    .el-loading-spinner {
        top: 70px;
    }
    .job-list-pagination.el-pagination {
        margin: 40px 0 20px 0;
        padding: 0;
        font-weight: normal;
        .btn-next, .btn-prev {
            height: 38px;
            border: 1px solid;
        }
        .btn-prev {
            border-right: none;
            border-radius: 4px 0 0 4px;
        }
        .btn-next {
            border-left: none;
            border-radius: 0 4px 4px 0;
        }
        .el-pagination__sizes .el-input{
            width: 87px;
            .el-input__inner {
                color: #666;
                .el-select__caret{
                    color: #999;
                }
            }
        }
        .el-pager {
            border-top: 1px solid;
            border-bottom: 1px solid;
            li {
                min-width: 36px;
                height: 36px;
                line-height: 36px;
                font-size: 14px;
            }
        }
        .btn-next, .btn-prev, .el-pager {
            border-color: #DDDDDD;
        }
        .el-input__inner, .el-pagination__total, .el-pagination__jump {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
        }
        .pagination-text{
            color: #999;
            span{
                height: 38px;
                line-height: 38px;
                font-size: 14px;
                .el-input{
                    width: 48px;
                    margin: 0 5px;
                    &__inner{
                        height: 38px;
                        line-height: 38px;
                        padding: 0 5px;
                    }
                }
                &:nth-of-type(2){
                    margin-left: 5px;
                    color: $primary;
                    cursor: pointer;
                }
            }
        }
        .el-pagination__total,
        .el-pagination__sizes,
        .pagination-text {
            float: right;
        }
    }
}
</style>