<template>
    <div class="market-job-item">
        <div class="job-header">
            <div class="job-title">
                <!-- <router-link target="_blank" :to="{path:`/Job/${job.jobItem.id}`,query: {curRecommendJobType: currentJobType}}"
                >
                    <span 
                        class="job-name ellipsis-overflow"
                        :class="job.jobItem.isSleeping ? 'is-sleeping' : ''"
                        :title="job.jobItem.name"
                    >
                        {{job.jobItem.name || ''}}
                    </span>
                </router-link> -->
                <!-- <a
                    class="job-name ellipsis-overflow"
                    :class="job.jobItem.isSleeping ? 'is-sleeping' : ''"
                    :href="'/Headhunting/MyCompany.html#/Job/' + job.jobItem.id + `?curRecommendJobType=${currentJobType}`"
                    :title="job.jobItem.name"
                    target="_blank"
                >
                    {{job.jobItem.name || ''}}
                </a> -->
                <!-- <a
                    class="job-name ellipsis-overflow"
                    :class="job.jobItem.isSleeping ? 'is-sleeping' : ''"
                    :href="'/Headhunting/MyCompany.html#/Job/' + job.jobItem.id"
                    :title="job.jobItem.name"
                    target="_blank"
                > -->
                <a
                    class="job-name ellipsis-overflow"
                    :class="job.jobItem.isSleeping ? 'is-sleeping' : ''"
                    :href="'/#/Job/' + job.jobItem.id"
                    :title="job.jobItem.name"
                    target="_blank"
                >
                    {{job.jobItem.name || ''}}
                </a>
                <span class="job-city ellipsis-overflow" v-if="currentJobType!=3">[{{job.jobItem.location || ''}}]</span>
                <span class="job-city ellipsis-overflow" v-if="currentJobType==3">[{{job.jobItem.locationText}}]</span>
                <div class="job-tags">
                    <el-tooltip
                        v-for="item in job.jobItem.activityTags"
                        :key="item.id"
                        effect="light"
                        placement="right"
                        :disabled="!item.description"
                        v-show="item.id !== 'StandstillJob' && item.id != 'FirstOrderActivityJob'"
                        popper-class="job-tag-description"
                    >
                        <div slot="content" v-html="item.description"></div>
                        <span
                            class="job-tag"
                            :class="`job-tag-${jobActivityTag[item.id].theme}`"
                        >
                            {{item.name}}
                        </span>
                    </el-tooltip>
                    <span v-if="isNew(job.created) && currentJobType==3" class="job-tag job-tag-warning">New</span>
                    <!-- 5 代 3 hr  4a2a -->
                    <span v-if="job.jobType == 5 && currentJobType==3" class="job-tag job-tag-info">代运营</span>
                    <span v-if="job.jobType == 3 && currentJobType==3" class="job-tag job-tag-info">HR</span>

                    <span
                        v-if="job.jobItem.status !== 1"
                        class="job-tag"
                        :class="`job-tag-${jobStatus[job.jobItem.status].theme}`"
                    >
                        {{jobStatus[job.jobItem.status].text}}
                    </span>
                    <!-- <span class="job-tag job-tag-primary" v-if="job.jobItem.expectedRank">{{job.jobItem.expectedRank}}</span> -->
                    <!-- <span v-if="currentJobType==3" class="job-tag job-tag-info">{{job.recruitType==0 ? '外包' : '猎头'}}</span> -->
                    <span v-if="currentJobType==3 && job.emergencyDegree==1" class="job-tag job-tag-danger">紧急</span>
                    <span v-if="currentJobType==3 && job.difficultDegree==1" class="job-tag job-tag-danger">较难</span>
                </div>
            </div>
            <span
                class="job-sticky"
                v-if="!job.jobItem.enableIntelligentRecommend && job.jobItem.isSticky"
                title="已申领预付款"
            >
                置顶
            </span>
        </div>
        <div class="job-bottom">
            <div class="job-intro">
                <div class="job-info">
                    <span class="job-split" v-if="currentJobType!=3">{{job.jobItem.salary == '0K-0K' ? '面议' : job.jobItem.salary}}</span>
                    <span class="job-split" v-if="currentJobType==3">{{job.jobItem.minSalary==0&&job.jobItem.maxSalary==0 ? '面议' : (job.jobItem.minSalary + 'K-' + job.jobItem.maxSalary + 'K')}}</span>
                    <span class="job-split" v-if="currentJobType!=3">{{ job.jobItem.degree }}</span>
                    <span class="job-split" v-if="currentJobType==3">
                        <span v-if="job.jobItem.minYearOfExperience == 0">经验不限</span>
                        <span v-else>{{job.jobItem.minYearOfExperience | filterYearOfExperience}}经验</span>
                    </span>
                    <span class="job-split" v-else>
                        {{job.jobItem.yearOfExperience}}
                    </span>
                    <span class="job-split" v-if="currentJobType==3">{{ job.jobItem.minDegree | degreeFilter }}</span>
                    <span>需 {{job.jobItem.recruitingCount}} 人</span>
                </div>
                <div class="job-promise">
                    佣金
                    <span v-if="currentJobType!=3" class="text-warning job-promise-detail">{{job.jobItem.commissionValue.indexOf('0K-0K') > -1 ? '按比例' : job.jobItem.commissionValue}}</span>
                    <!-- <span v-if="currentJobType==3 && ((job.jobItem.minSalary==0&&job.jobItem.maxSalary==0) || job.commissionType == '1')" class="text-warning job-promise-detail">{{job.jobItem.minSalary==0&&job.jobItem.maxSalary==0 ? `按比例(${job.jobItem.commissionValue}%)` : job.jobItem.commissionValue+'K'}}</span>
                    <span v-if="currentJobType==3 && job.jobItem.minSalary!=0&&job.jobItem.maxSalary!=0 && job.commissionType == '0'" class="text-warning job-promise-detail">{{job.jobItem.minSalary*12*job.jobItem.commissionValue/100}}K - {{job.jobItem.maxSalary*12*job.jobItem.commissionValue/100}}K({{job.jobItem.commissionValue}}%)</span> -->
                    <span 
                        v-if="currentJobType==3 && job.commissionType == '0'" 
                        class="text-warning job-promise-detail"
                    >
                        {{ job.jobItem.minSalary == 0 && job.jobItem.maxSalary == 0 ? `按比例(${job.jobItem.commissionValue}%)` : ((job.jobItem.minSalary * 12 * job.jobItem.commissionValue / 100).toFixed(2) + 'K - ' + (job.jobItem.maxSalary * 12 * job.jobItem.commissionValue / 100).toFixed(2) + 'K(' + job.jobItem.commissionValue + '%)') }}
                    </span>
                    <span 
                        v-if="currentJobType==3 && job.commissionType == '1'" 
                        class="text-warning job-promise-detail"
                    >
                        {{ job.jobItem.commissionValue + 'K' }}
                    </span>
                    保证期 <span class="text-warning job-promise-detail">{{job.jobItem.guaranteePeriod}}</span> 个月
                </div>
                <div
                    class="job-firm-commission"
                    v-if="currentJobType!=3 && user.headhuntingFirmType > 0 && ((user.headhuntingFirmType > 2 && job.jobItem.headhuntingFirmCommissionValue > 0) || (user.headhuntingFirmType < 3 && job.jobItem.otherFirmCommissionValue > 0))"
                >
                    分佣比例
                    <span class="text-grey job-firm-commission-detail">{{user.headhuntingFirmType > 2 ? job.jobItem.headhuntingFirmCommissionValue + '%' : job.jobItem.otherFirmCommissionValue + '%'}}</span>
                    <el-tooltip
                        popper-class="commission-rate-text"
                        effect="light"
                        content="简历提供者，分成比例需要和平台运营确认。"
                        placement="right"
                        v-if="user.headhuntingFirmType < 3"
                    >
                        <div class="tip-icon">
                            <font-icon class="default" href="#icon-talent_qd"></font-icon>
                            <font-icon class="hover" href="#icon-talent_ql"></font-icon>
                        </div>
                    </el-tooltip>
                </div>
                <!-- <div
                    class="job-firm-commission"
                    v-if="currentJobType==3 && job.commissionType=='0'"
                >
                    分佣比例
                    <span class="text-grey job-firm-commission-detail">{{job.jobItem.commissionValue + '%'}}</span>
                    <el-tooltip
                        popper-class="commission-rate-text"
                        effect="light"
                        content="简历提供者，分成比例需要和平台运营确认。"
                        placement="right"
                    >
                        <div class="tip-icon">
                            <font-icon class="default" href="#icon-talent_qd"></font-icon>
                            <font-icon class="hover" href="#icon-talent_ql"></font-icon>
                        </div>
                    </el-tooltip>
                </div> -->
            </div>
            <div class="job-status" v-if="currentJobType == '0'">
                <!-- :href="job.jobItem.canViewFirmDetail ? `/Headhunting/MyCompany.html#/Customer/${job.jobItem.customerId}` : 'javascript:void(0)'" -->
                <a
                    class="job-firmname"
                    :class="{'firmname-no-view': !job.jobItem.canViewFirmDetail}"
                    :title="job.jobItem.firmShortName || job.jobItem.firmName"
                    :href="job.jobItem.canViewFirmDetail ? `/#/Customer/${job.jobItem.customerId}` : 'javascript:void(0)'"
                    :target="job.jobItem.canViewFirmDetail ? '_blank' : '_self'"
                >
                    {{job.jobItem.firmShortName || job.jobItem.firmName}}
                </a>
                <div class="job-channel">
                    <span
                        class="job-channel-indicator"
                        :class="job.isOnline ? 'active' : ''"
                    ></span>
                    <span class="job-channel-user">{{job.jobItem.realName}}（{{job.isOnline ? '在线' : offlineFilter(job.offlineTime)}}）</span>
                </div>
                <div class="job-entrance">
                    <span
                        class="job-entrance-count"
                        v-if="job.onlineCount > 0"
                    >
                        {{job.onlineCount}}人正在讨论
                    </span>
                    <span
                        class="text-operate-btn green"
                        v-if="!job.jobItem.isVerifying"
                        @click="goToChannel(job.jobItem)"
                    >
                        立即参与 &raquo;
                    </span>
                </div>
            </div>
            <div class="job-status-hr" v-if="currentJobType == 3">
                <span class="job-publisher" v-if="job.recruiter">{{job.recruiter.realName}}</span>
                <a
                    class="job-firmname"
                    :title="[3,5].includes(job.jobType) ? (job.definedCustomerName || job.firmName) : job.firmName"
                    :href="job.jobType == 3 ? `/Headhunting/#/hrFirm?customerId=${job.jobItem.firmId}` : 'javascript:void(0)'"
                    :target="job.jobType == 3 ? '_blank' : '_self'"
                >
                    {{[3,5].includes(job.jobType) ? (job.definedCustomerName || job.firmName) : job.firmName}}
                </a>
            </div>
            <div class="job-panel">
                <el-button
                    type="primary"
                    @click="getOrder(job.jobItem)"
                    v-if="!(job.jobItem.isMyOrder || job.jobItem.isMyJob)"
                    :loading="grabLoading"
                >
                    抢单
                </el-button>
                <el-button
                    class="recommend-button"
                    @click="recommend(job.jobItem, currentJobType)"
                    v-if="(job.jobItem.isMyOrder || job.jobItem.isMyJob)"
                >
                    推荐
                </el-button>
                <a
                    class="job-fit"
                    :href="job.jobItem.fitUrl"
                    target="_blank"
                    v-if="job.jobItem.fitCount"
                >
                    <i class="el-icon-search"></i>
                    有 {{job.jobItem.fitCount || 0}} 人适合此职位
                </a>
            </div>
        </div>
  </div>
</template>

<script>
import moment from 'moment';
import jobActivityTag from '#/js/config/jobActivityTag.json';
import jobStatus from '#/js/config/jobStatus.json';
import DegreeKeyValue from '#/js/config/degreeKeyValue.json';
export default {
    props: {
        job: Object,
        currentJobType: String
    },
    data() {
        return {
            jobStatus: jobStatus,
            jobActivityTag: jobActivityTag,
            grabLoading: false,
        }
    },
    computed: {
        user() {
            return this.$store.state.user.userInfo;
        }
    },
    filters: {
        degreeFilter(val) {
            return DegreeKeyValue[val];
        },
        filterYearOfExperience(val) {
            if(val == 1) {
                return '1-3年'
            } else if(val == 3) {
                return '3-5年'
            } else if(val == 5) {
                return '5-10年'
            } else if(val == 10) {
                return '10年以上'
            }
        }
    },
    mounted() {
    },
    methods: {
        isNew(created) {
            const after3Days = moment(created).add(3, 'days');
            return moment().isSameOrBefore(after3Days);
        },
        offlineFilter(val) {
            var time = null;
            time = new Date().getTime() - new Date(val).getTime();
            var offerlineText = '';
            if(time >= 31536000000) {
                offerlineText = parseInt(time / 31536000000) + '年前来过';
            } else if(time >= 2592000000) {
                offerlineText = parseInt(time / 2592000000) + '个月前来过';
            } else if(time >= 604800000) {
                offerlineText = parseInt(time / 604800000) + '周前来过';
            } else if(time >= 86400000) {
                offerlineText = parseInt(time / 86400000) + '天前来过';
            } else if(time >= 3600000) {
                offerlineText = parseInt(time / 3600000) + '小时前来过';
            } else if(time >= 60000) {
                offerlineText = parseInt(time / 60000) + '分钟前来过';
            } else if(time >= 1000) {
                offerlineText = parseInt(time / 1000) + '秒前来过';
            } else {
                offerlineText = '刚刚来过';
            }
            return offerlineText;
        },
        getOrder(job) {
            let _requestParam = {};
            // if(this.currentJobType == 3) {
                _requestParam = {
                    url: "/openapi/Job/%p/Order".replace(/\%p/ig, job.id),
                    method: "POST",
                    baseURL: "LbdOpenApi",
                    data: {
                        jobId: job.id,
                    },
                }
            // } else {
            //     _requestParam = {
            //         url: "/Headhunting/Order/GetInternalOrder",
            //         method: "POST",
            //         headers: {
            //             'Content-Type': 'application/x-www-form-urlencoded'
            //         },
            //         data: {
            //             jobId: job.id,
            //             __RequestVerificationToken: this.$store.state.verificationToken.verificationToken
            //         },
            //         throwBusinessError: true
            //     }
            // }
            this.grabLoading = true;
            _request(
                _requestParam
            ).
            then(()=>{
                shortTips('抢单成功！');
                // location.href = `/Headhunting/MyCompany.html?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder&curRecommendJobType=${this.currentJobType}&marketJobType=${job.jobType}`;
                // location.href = `/?jobName=${encodeURI(job.name)}#/resultRecommend?jobId=${job.id}&fromPage=GrabOrder&curRecommendJobType=${this.currentJobType}&marketJobType=${job.jobType}`;
                this.$emit('grab-success-callback', job);
            })
            .catch(err => {
                if(err.data) {
                    switch (err.data.code) {
                        case "NotFoundFirm":
                            this.$confirm('您尚未加入公司，为更好保障您做单的权益，请加入公司。', '提示', {
                                confirmButtonText: '加入公司',
                                cancelButtonText: '取消'
                            }).then(() => {
                                window.open(err.data.url);
                            }).catch(() => {});
                            break;
                        case "NotConfirmEmail":
                            this.$confirm('您的邮箱尚未验证，为您能及时获取职位信息，请验证。', '提示', {
                                confirmButtonText: '验证邮箱',
                                cancelButtonText: '取消'
                            }).then(() => {
                                // window.open("/Headhunting/MyCompany.html#/emailVerify");
                                // window.open("/Headhunting/#/personalCenter?isEmailVerify=true");
                                window.open("/Headhunting/#/personalCenter?Tab=accountSet");
                            }).catch(() => {});
                            break;
                        case "Applying":
                            this.$alert('还未能认证您所在的公司，请等待审核通过后再抢单！', {
                                confirmButtonText: '我知道了'
                            });
                            break;
                        case "FirmNotVerified":
                            if(this.user.firmVerificationStatus == 0) {
                                this.$alert('市场职位抢单需要公司的信息被审核验证', '提示', {
                                    confirmButtonText: '申请认证',
                                    callback: action => {
                                        if(action == 'conform') {
                                            window.open("/Headhunting/Firm/Verify");
                                        }
                                    }
                                });
                            } else if(this.user.firmVerificationStatus == 1) {
                                this.$alert('你的公司信息正在审核中，请等待...', {
                                    confirmButtonText: '我知道了'
                                });
                            }
                            break;
                        default:
                            shortTips("系统异常，请稍后重试");
                            break;
                    }
                } else {
                    shortTips(err.message);
                }
            })
            .finally(() => {
                this.grabLoading = false;
            });
        },
        recommend(job, currentJobType) {
            this.$emit('recommend', job, currentJobType);
        },
        goToChannel(job) {
            if(!job.isMyOrder) {
                shortTips('请先抢单，抢单后即可参与群聊');
                return false;
            }
            const previewJobChannelData = {
                jobId: job.id,
                jobType: job.jobType,
                name: job.name,
                location: job.location,
                commissionValue: encodeURIComponent(job.commissionValue),
                guaranteePeriod: job.guaranteePeriod
            }
            if(localStorage){
                localStorage.setItem('jumpJobChannel', job.id);
            }
            window.open(`/My/Messages/${job.id}?previewJobChannelData=${JSON.stringify(previewJobChannelData)}`, '_blank');
        }
    }
}
</script>

<style lang="scss" scope>
    .ellipsis-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .text-warning {
        color: #ff7200;
    }
    .text-grey {
        color: #666;
    }
    .market-job-item {
        padding: 20px;
        border-bottom: 1px solid #ececec;
        &:nth-last-of-type(1) {
            border-bottom: none;
        }
        &:hover {
            background-color: #f6f6f6;
        }
        .job-header {
            position: relative;
            .job-title {
                line-height: 46px;
                height: 46px;
                display: flex;
                align-items: center;
                .job-name {
                    font-size: 16px;
                    max-width: 400px;
                    color: $primary;
                    &.is-sleeping {
                        color: #b9b9b9;
                        pointer-events: none;
                    }
                }
                .job-city {
                    font-size: 16px;
                    margin-left: 15px;
                    margin-right: 10px;
                    color: #333;
                    max-width: 300px;
                }
                .job-tags{
                    display: inline-flex;
                    margin-bottom: 0;
                    user-select: none;
                    .job-tag{
                        text-align: center;
                        font-size: 12px;
                        border: 1px solid #b9b9b9;
                        padding: 0 6px;
                        border-radius: 10px;
                        color: #b9b9b9;
                        margin-right: 7px;
                        line-height: 16px;
                        height: 18px;
                        white-space: nowrap;
                        &.job-tag-danger{
                            border-color: #ff493c;
                            color: #ff493c;
                        }
                        &.job-tag-primary{
                            border-color: #38bc9d;
                            color: #38bc9d;
                        }
                        &.job-tag-info{
                            border-color: #39f;
                            color: #39f;
                        }
                        &.job-tag-warning{
                            border-color: #ff7200;
                            color: #ff7200;
                        }
                    }
                }
            }
            .job-sticky {
                margin-top: 10px;
                position: absolute;
                right: 5px;
                margin-right: 6px;
                color: #ff7200;
                border-color: #ff7200;
                border-radius: 2px;
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                padding: 0 6px;
            }
        }
        .job-bottom {
            color: #666;
            line-height: 22px;
            display: flex;
            justify-content: space-between;
            .job-intro {
                width: 400px;
                .job-info {
                    color: #666;
                    margin-bottom: 10px;
                    .job-split {
                        border-right: 1px solid #d3d3d3;
                        padding-right: 3px;
                        margin-right: 3px;
                    }
                }
                .job-promise {
                    margin-bottom: 10px;
                }
                .job-promise, .job-firm-commission {
                    color: #b9b9b9;
                }
                .job-promise-detail, .job-firm-commission-detail {
                    margin: 0 5px;
                }
                .job-firm-commission-detail {
                    margin-right: 0;
                }
                .tip-icon {
                    display: inline-block;
                    width: 22px;
                    height: 22px;
                    margin-left: 5px;
                    .font-icon {
                        width: 16px;
                        height: 16px;
                        margin-top: -3px;
                        opacity: 1;
                    }
                    .hover {
                        display: none;
                    }
                    &:hover {
                        .default {
                            display: none;
                        }
                        .hover {
                            display: inline-block;
                        }
                    }
                }
            }
            .job-status {
                width: 178px;
                .job-firmname {
                    display: block;
                    color: #666;
                    &.firmname-no-view {
                        cursor: default;
                    }
                }
                .job-channel {
                    position: relative;
                    padding-left: 12px;
                    .job-channel-indicator {
                        width: 8px;
                        height: 8px;
                        display: inline-block;
                        border-radius: 50%;
                        background-color: #b9b9b9;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        &.active {
                            background-color: #237fbb;
                        }
                    }
                    .job-channel-user {
                        white-space: wrap;
                    }
                }
            }
            .job-status-hr {
                width: 178px;
                text-align: center;
                .job-publisher {
                    font-size: 14px;
                    color: #666666;
                }
                .job-firmname {
                    display: block;
                    font-size: 14px;
                    color: #B9B9B9;
                    &.firmname-no-view {
                        cursor: default;
                    }
                }
            }
            .job-panel {
                width: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                .el-button {
                    width: 100px;
                }
                .recommend-button {
                    color: $primary;
                    border-color: $primary;
                    background-color: transparent;
                    &:hover, &:focus {
                        background-color: transparent;
                        color: $primary;
                        border-color: $primary;
                    }
                }
                .job-fit {
                    color: $primary;
                    .el-icon-search {
                        margin-right: 4px;
                    }
                }
            }
        }
    }
</style>
<style lang="scss">
    .commission-rate-text.el-tooltip__popper.is-light {
        margin-top: 50px;
        .popper__arrow {
            display: none;
        }
    }
</style>
