<template>
    <div class="market-job-header">
        <el-tabs
            class="market-job-tab"
            v-model="params.type"
            type="card"
            @tab-click="clickType"
        >
            <el-tab-pane
                label="A2A职位"
                name="0"
            ></el-tab-pane>
            <el-tab-pane
                label="HR直招"
                name="3"
            ></el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
export default {
    props: {
        jobList: Array
    },
    components: {
    },
    data() {
        return {
            params: {
                type: "0",
                orderBy: 0
            },
            isCFUser: false,
            isTypeChanged: false,
        }
    },
    watch: {
        params: {
            handler: function() {
                this.search(false);
            },
            deep: true
        },
        '$store.state.user.userInfo':{
            handler(val,oldVal){
                console.log(val,oldVal)
                if(val){
                    this.isCFUser = val.isCFUser;
                    // if(this.isCFUser) {
                    //     this.$nextTick(()=>{
                    //         document.getElementById(`tab-3`).style.display = 'inline-block';
                    //     })
                    // } else {
                    //     this.$nextTick(()=>{
                    //         document.getElementById(`tab-3`).style.display = 'none';
                    //     })
                    // }
                }
            },
            deep: true
        }
    },
    mounted() {
        if(this.$store.state.user.userInfo){
            this.isCFUser = this.$store.state.user.userInfo.isCFUser;
        }
        // console.log(this.isCFUser);
        // if(this.isCFUser) {
        //     this.$nextTick(()=>{
        //         document.getElementById(`tab-3`).style.display = 'inline-block';
        //     })
        // } else {
        //     this.$nextTick(()=>{
        //         document.getElementById(`tab-3`).style.display = 'none';
        //     })
        // }
    },
    methods: {
        clickType(tab) {
            this.isTypeChanged = true;
        },
        search(silent) {
            // console.log(1111)
            let searchJson = Object.assign({}, this.params);
            this.$emit('search', searchJson, silent, this.isTypeChanged);
            // console.log(this.params);
        },
    }
}
</script>

<style lang="scss" scope>
.market-job-header {
    padding: 0 36px;
    width: 100%;
    height: 60px;
    line-height: 60px;
    color: #999999;
    font-size: 16px;
    border-bottom: 1px solid #eee;
    position: relative;
    .market-job-tab {
        display: inline-block;
        .el-tabs__header {
            border-bottom: none;
            margin-bottom: 0;
            .el-tabs__nav {
                border: none;
                .el-tabs__item {
                    // width: 100px;
                    height: 60px;
                    line-height: 60px;
                    margin-right: 70px;
                    padding-left: 0;
                    padding-right: 0;
                    border-left: none;
                    border-bottom: none;
                    font-weight: 400;
                    font-size: 14px;
                    text-align: center;
                    color: #333;
                    &.is-active {
                        color: #333;
                        border-bottom: 4px solid #38bc9d;
                        position: relative;
                        color: #38bc9d;
                        &::after {
                            width: 128px;
                            height: 1px;
                            display: inline-block;
                            // background-color: #fff;
                            position: absolute;
                            bottom: -1px;
                            left: 1px;
                        }
                    }
                }
            }
        }
    }
}
</style>